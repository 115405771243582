import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

const data = {
  loaders: {
    appLoader: `<div class="loader-ios">
    <svg width="27" height="27" viewBox="0 0 27 27" class="svg">
      <path
        d="M18.696,10.5c-0.275-0.479-0.113-1.09,0.365-1.367l4.759-2.751c0.482-0.273,1.095-0.11,1.37,0.368 c0.276,0.479,0.115,1.092-0.364,1.364l-4.764,2.751C19.583,11.141,18.973,10.977,18.696,10.5z"
        class="path1"
      />
      <path
        d="M16.133,6.938l2.75-4.765c0.276-0.478,0.889-0.643,1.367-0.366c0.479,0.276,0.641,0.886,0.365,1.366l-2.748,4.762 C17.591,8.415,16.979,8.58,16.5,8.303C16.021,8.027,15.856,7.414,16.133,6.938z"
        class="path2"
      />
      <path
        d="M13.499,7.5c-0.552,0-1-0.448-1-1.001V1c0-0.554,0.448-1,1-1c0.554,0,1.003,0.447,1.003,1v5.499 C14.5,7.053,14.053,7.5,13.499,7.5z"
        class="path3"
      />
      <path
        d="M8.303,10.5c-0.277,0.477-0.888,0.641-1.365,0.365L2.175,8.114C1.697,7.842,1.532,7.229,1.808,6.75 c0.277-0.479,0.89-0.642,1.367-0.368l4.762,2.751C8.416,9.41,8.58,10.021,8.303,10.5z"
        class="path4"
      />
      <path
        d="M9.133,7.937l-2.75-4.763c-0.276-0.48-0.111-1.09,0.365-1.366c0.479-0.277,1.09-0.114,1.367,0.366l2.75,4.765 c0.274,0.476,0.112,1.088-0.367,1.364C10.021,8.581,9.409,8.415,9.133,7.937z"
        class="path5"
      />
      <path
        d="M6.499,14.5H1c-0.554,0-1-0.448-1-1c0-0.554,0.447-1.001,1-1.001h5.499c0.552,0,1.001,0.448,1.001,1.001 C7.5,14.052,7.052,14.5,6.499,14.5z"
        class="path6"
      />
      <path
        d="M8.303,16.502c0.277,0.478,0.113,1.088-0.365,1.366l-4.762,2.749c-0.478,0.273-1.091,0.112-1.368-0.366 c-0.276-0.479-0.111-1.089,0.367-1.368l4.762-2.748C7.415,15.856,8.026,16.021,8.303,16.502z"
        class="path7"
      />
      <path
        d="M10.866,20.062l-2.75,4.767c-0.277,0.475-0.89,0.639-1.367,0.362c-0.477-0.277-0.642-0.886-0.365-1.365l2.75-4.764 c0.277-0.477,0.888-0.638,1.366-0.365C10.978,18.974,11.141,19.585,10.866,20.062z"
        class="path8"
      />
      <path
        d="M13.499,19.502c0.554,0,1.003,0.448,1.003,1.002v5.498c0,0.55-0.448,0.999-1.003,0.999c-0.552,0-1-0.447-1-0.999v-5.498 C12.499,19.95,12.946,19.502,13.499,19.502z"
        class="path9"
      />
      <path
        d="M17.867,19.062l2.748,4.764c0.275,0.479,0.113,1.088-0.365,1.365c-0.479,0.276-1.091,0.112-1.367-0.362l-2.75-4.767 c-0.276-0.477-0.111-1.088,0.367-1.365C16.979,18.424,17.591,18.585,17.867,19.062z"
        class="path10"
      />
      <path
        d="M18.696,16.502c0.276-0.48,0.887-0.646,1.365-0.367l4.765,2.748c0.479,0.279,0.64,0.889,0.364,1.368 c-0.275,0.479-0.888,0.64-1.37,0.366l-4.759-2.749C18.583,17.59,18.421,16.979,18.696,16.502z"
        class="path11"
      />
      <path
        d="M25.998,12.499h-5.501c-0.552,0-1.001,0.448-1.001,1.001c0,0.552,0.447,1,1.001,1h5.501c0.554,0,1.002-0.448,1.002-1 C27,12.946,26.552,12.499,25.998,12.499z"
        class="path12"
      />
    </svg>
    <div id="root-loading_text">Application Loading...</div>
  </div>`,
  },
};

const routes = constructRoutes(
  document.querySelector("#single-spa-layout"),
  data
);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

const hashString = window.location.hash;
const searchString = window.location.search;
const pathName = window.location.pathname;
let queryString = "";
if (hashString) {
  queryString = hashString.substr(hashString.indexOf("?") + 1);
} else {
  queryString = searchString;
}
const params = new Proxy(new URLSearchParams(queryString), {
  get: (searchParams, prop) => searchParams.get(prop),
});
if (params.appName && params.appId && params.keep != "0") {
  sessionStorage.setItem("appName", params.appName);
  sessionStorage.setItem("appId", params.appId);
  localStorage.setItem(
    "link",
    `${pathName}${(searchString ? searchString : hashString) + "&keep=0" || ""}`
  );
}
applications.forEach(registerApplication);
start();

// Define the custom event
const logOutEvent = new Event("logoutEvent", {
  bubbles: true,
});

// Store the original fetch function
const originalFetch = window.fetch;

window.fetch = async function (...args) {
  let response = null;
  try {
    response = await originalFetch(...args);
  } catch (error) {
    console.error("API error", error);
  }

  if (response && response.status === 401) {
    console.error("401 Unauthorized Error, logging out...");
    window.dispatchEvent(logOutEvent);
  }

  return response;
};

window.addEventListener("single-spa:before-mount-routing-event", (e) => {
  if (
    (e.detail.newUrl.split("#")[0].split("/").includes("co2monitoring_old") ||
      e.detail.newUrl.split("#")[0].split("/").includes("h2o2m") ||
      e.detail.newUrl.split("#")[0].split("/").includes("epm_old") ||
      e.detail.newUrl.split("#")[0].split("/").includes("epm_vguard_old") ||
      e.detail.newUrl.split("#")[0].split("/").includes("co2_h2o2_old") ||
      e.detail.newUrl.split("#")[0].split("/").includes("demoapp") ||
      e.detail.newUrl
        .split("#")[0]
        .split("/")
        .includes("railwayassetsmonitoring_old") ||
      e.detail.newUrl.split("#")[0].split("/").includes("prefabtestapp") ||
      e.detail.newUrl.split("#")[0].split("/").includes("ums_old") ||
      e.detail.newUrl.split("#")[0].split("/").includes("epm_europe") ||
      e.detail.newUrl.split("#")[0].split("/").includes("delpheonepm_old") ||
      e.detail.newUrl.split("#")[0].split("/").includes("oee")) &&
    e.detail.newUrl.split("#")[0] !== e.detail.oldUrl.split("#")[0]
  ) {
    $("#co2m")?.hide();
    $("#epm_europe")?.hide();
    window.location.reload();
  }

  if (
    !e.detail.newUrl.includes("#") &&
    e.detail.newUrl.split("/").includes("delpheonepm") &&
    e.detail.newUrl.split("/")[3] !== e.detail.oldUrl.split("/")[3]
  ) {
    window.location.reload();
  }
  if (!e.detail?.newUrl?.split("/")?.includes("railwayassetsmonitoring#")) {
    //Remove the bottom navigation for Railiot application that is under the body element
    const elementsToRemove = document.getElementsByClassName(
      "del-rail-iot-bottom-nav"
    );

    if (elementsToRemove?.length > 0) {
      const elementsArray = Array.from(elementsToRemove);
      elementsArray.forEach((element) =>
        element.parentNode.removeChild(element)
      );
    }
  }
});

window.addEventListener("single-spa:before-app-change", (e) => {
  if (
    e.detail?.newAppStatuses["@DelphEon/railwayassetsmonitoring"] ===
    "NOT_MOUNTED"
  ) {
    const elementsToRemove = Array.from(
      document.getElementsByClassName(
        "toastify on  toastify-right toastify-top"
      )
    ).filter((element) => element.parentNode);

    elementsToRemove.forEach((element) =>
      element.parentNode.removeChild(element)
    );
  }
  const isIframe = window.self !== window.top;
  if (
    (e.detail?.newAppStatuses["@DelphEon/Platform"] === "MOUNTED" ||
      e.detail.newUrl.split("/")[3] === "") &&
    !isIframe
  ) {
    sessionStorage.removeItem("saved-start-date");
    sessionStorage.removeItem("saved-end-date");
    sessionStorage.removeItem("saved-node-id");
    sessionStorage.removeItem("multiaxischart-selected-timeperiod");
    sessionStorage.removeItem("multiaxischart-saved-start-date");
    sessionStorage.removeItem("multiaxischart-saved-end-date");
  }
});
